import React from "react"
import SEO from "../components/seo";
import Layout from "../components/layout"

import Resource from "../components/resource"
import AboutCta from "../components/about-cta";

// hooks
import useResourcesFilters from '../hooks/use-resources-filters';
import useResources from '../hooks/use-resources';


const Resources = () => {
  return (
    <Layout>
      <SEO title={`Resources`} description="Browse through our library of articles, apps, products, and videos to help maintain a healthy relationship with technology"/>
      <Resource
        filters={useResourcesFilters()}
        data={useResources()}
        page="resources"
        count={20}
      >
      </Resource>
      <AboutCta hasImage={false}/>
    </Layout>
  )
}


export default Resources;
